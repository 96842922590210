// @ts-ignore
import htmlFromTilda from './planMenuTilda.html'
import {useEffect, useLayoutEffect} from "react";
import AppContainer from "../../../components/appContainer";

export interface PlanMenuTildaV1Params {
    onClick: () => void;
}

const PlanMenuTildaV1 = ({onClick}: PlanMenuTildaV1Params) => {
    useLayoutEffect(() => {
        setTimeout(() => {
            // Костыль для tilda, чтобы она раскрасила bullets.
            // @ts-ignore
            t_onReady(function () {
                // @ts-ignore
                t_onFuncLoad('t__paintIcons', function () {
                    // @ts-ignore
                    t__paintIcons('848224051', '.t997__img');
                });
            });
        }, 50);
    }, []);

    useEffect(() => {
        document.querySelectorAll('a[href="#next"]').forEach(link => {
            link.addEventListener('click', event => {
                event.preventDefault();
                onClick();
            });
        });
    }, [onClick]);

    return (
        <AppContainer maxWidth="xl">
            <div dangerouslySetInnerHTML={{__html: htmlFromTilda}} className="withoutExtPadding"/>
        </AppContainer>
    );
};

export default PlanMenuTildaV1;