import Stack from "@mui/material/Stack";
import {Box, Button, Container, Typography} from "@mui/material";
import {Transitions} from "../../components/transitions";
import {ROUTES, useAppNavigate} from "../../helpers/routing";
import React, {useCallback} from "react";
import AppContainer from "../../components/appContainer";
import Grid2 from "@mui/material/Unstable_Grid2";
import {isAndroidOS} from "../../helpers/isAndroid";
import {sendShownQuestion, sendStartedQuiz} from "../../helpers/analytics";
import PlanMenuTildaV1 from "./components/planMenuTildaV1";

const IntroPage = () => {
    const navigate = useAppNavigate();
    const handleNext = useCallback(() => {
        if (isAndroidOS()) {
            sendShownQuestion(1);
            sendStartedQuiz();
            navigate(ROUTES.QUIZ_QUESTION(1));
            return;
        }

        navigate(ROUTES.OS_SELECT);
    }, [navigate]);

    return (
        // @ts-ignore
        <Transitions className="transition-h100">
            <PlanMenuTildaV1 onClick={handleNext}/>
        </Transitions>
    );
}

export default IntroPage;