import './App.css';
import {Box, Container, createTheme, ThemeProvider} from "@mui/material";
import {themeOptions} from "./themeOptions";
import {createBrowserRouter, RouterProvider, ScrollRestoration} from "react-router-dom";
import IntroPage from "./pages/intro";
import {AnimatePresence} from "framer-motion";
import QuizPage from "./pages/quiz";
import LoadingPlan from "./pages/loadingPlan";
import {OverallQuestionsCount} from "./data/quiz";
import {useEffect, useLayoutEffect} from "react";
import EndPaymentPage from "./pages/endPayment";
import RedirectPage from "./pages/redirect";
import {configureAuth, configureAxios} from "./api/config";
import {ROUTES} from "./helpers/routing";
import RegisterAccountPage from "./pages/registerAccount";
import * as React from "react";
import store, {useTypedSelector} from "./store/store";
import {QuizState} from "./store/quizSlice";
import {AccountState} from "./store/accountSlice";
import PaywallPage from "./pages/paywall";
import OsSelectPage from "./pages/osSelect";
import PayPage from "./pages/pay";
import {Provider} from "react-redux";

const theme = createTheme(themeOptions);
const router = createBrowserRouter([
    {
        path: "/",
        element: <IntroPage/>,
    },
    {
        path: "/quiz/:questionNum",
        element: <QuizPage/>
    },
    {
        path: "/loading-plan",
        element: <LoadingPlan/>,
    },
    {
        path: "/subscribe",
        element: <PaywallPage/>
    },
    {
        path: "/end-payment",
        element: <EndPaymentPage/>
    },
    {
        path: "/redir/:to",
        element: <RedirectPage/>
    },
    {
        path: "/register-account",
        element: <RegisterAccountPage/>
    },
    {
        path: "/os-select",
        element: <OsSelectPage/>
    },
    {
        path: "/pay",
        element: <PayPage/>
    }
]);

const App: React.FC = () => {
    const quizState = useTypedSelector((state) => state.quiz);
    const accountState = useTypedSelector((state) => state.account);

    useLayoutEffect(() => {
        configureAxios();
        if (accountState.authToken) {
            configureAuth(accountState.authToken);
        }

        redirectToLastVisitedPage(quizState, accountState);
    }, [quizState, accountState]);

    return (
        <ThemeProvider theme={theme}>
            <Box className="App" sx={{p: 0}}>
                <AnimatePresence>
                    <RouterProvider router={router}/>
                </AnimatePresence>
            </Box>
        </ThemeProvider>
    );
}

const redirectToLastVisitedPage = (quizState: QuizState, accountState: AccountState): void => {
    const path: string = router.state.location.pathname;
    if (path !== '/') {
        return;
    }

    const lastAnswerNum: number = Math.max(...Object.keys(quizState.answers).map(Number));
    if (lastAnswerNum > 1 && lastAnswerNum < OverallQuestionsCount) {
        router.navigate(ROUTES.QUIZ_QUESTION((lastAnswerNum + 1)));
        return;
    }

    if (lastAnswerNum >= OverallQuestionsCount) {
        if (accountState.authToken) {
            router.navigate(ROUTES.SUBSCRIBE);
            return;
        }

        router.navigate(ROUTES.REGISTER_ACCOUNT);
    }
};

export default App;